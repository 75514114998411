
$transition: height 1s, width 1s, transform 1s;

.jiggle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .jiggle-bar {
        transition: all 0.25s;
        height: 20px;
        width: 16px;
        transition: $transition;
        margin-left: 0.1em;
        margin-right: 0.1em;
        border-radius: 10px;
    }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
    0% {
        transform: rotate(0deg);
    }
}

@keyframes translate {
    0% {
        height: 96px;
        width: 96px;
    }
    50% {
        height: 72px;
        width: 72px;
    }
    100% {
        height: 96px;
        width: 96px;
    }
}

@keyframes translate-small {
    0% {
        height: 24px;
        width: 24px;
    }
    50% {
        height: 16px;
        width: 16px;
    }
    100% {
        height: 24px;
        width: 24px;
    }
}