.jiggle {
    border-radius: 50%;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jiggle-bar {
    border: 1.5px solid white;
    transition: all 0.25s;
    height: 2px;
    width: 2px;
    background-color: white;
    margin-left: 0.1em;
    margin-right: 0.1em;
    border-radius: 10px;
}

.jiggle-xl {
    width: 200px;
    height: 200px;
}

.jiggle-xl .jiggle-bar {
    background-color: black;
    border: 1.5px solid black;
    width: 30px;
    border-radius: 24px;
}